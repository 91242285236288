webshop.ProductGrid = class {
    /* Options:
        - items: Items
        - settings: Webshop Settings
        - products_section: Products Wrapper
        - preference: If preference is not grid view, render but hide
    */
    constructor(options) {
        Object.assign(this, options);

        if (this.preference !== "Grid View") {
            this.products_section.addClass("hidden");
        }

        this.products_section.empty();
        this.make();
    }

    make() {
        let me = this;
        let html = ``;

        this.items.forEach(item => {
            let title = item.item_name || item.web_item_name || item.item_code || "";
            title = title.length > 90 ? title.substr(0, 90) + "..." : title;

            html += `<div class="col-sm-4 item-card"><div class="card text-left">`;
            html += me.get_image_html(item, title);
            html += me.get_card_body_html(item, title, me.settings);
            html += `</div></div>`;
        });

        let $product_wrapper = this.products_section;
        $product_wrapper.append(html);
    }

    get_image_html(item, title) {
        let image = item.website_image;

        if (image) {
            return `
                <div class="card-img-container">
                    <a href="/${ item.route || '#' }" style="text-decoration: none;">
                        <img itemprop="image" class="card-img" src="${ image }" alt="${ title }">
                    </a>
                </div>
            `;
        } else {
            return `
                <div class="card-img-container">
                    <a href="/${ item.route || '#' }" style="text-decoration: none;">
                        <div class="card-img-top no-image">
                            ${ frappe.get_abbr(title) }
                        </div>
                    </a>
                </div>
            `;
        }
    }

    get_card_body_html(item, title, settings) {
        let body_html = `
            <div class="card-body text-left card-body-flex" style="width:100%">
                <div style="margin-top: 1rem; display: flex;">
        `;
        body_html += this.get_title(item, title);

        // get floating elements
        if (!item.has_variants) {
            if (settings.enable_wishlist) {
                body_html += this.get_wishlist_icon(item);
            }
            if (settings.enabled) {
                body_html += this.get_cart_indicator(item);
            }
        }

        body_html += `</div>`;
        // body_html += `<div class="product-category" itemprop="name">${ item.item_group || '' }</div>`;
        // nxweb commented above line
        body_html += `<div class="product-category" itemprop="name">${ item.item_group || '' }<br> <b>Mfg Code:</b> ${ item.brand || '' } <br> <b>Mfg Part No:</b> ${ item.custom_mfg_partno || '' }</div>`;

        if (item.formatted_price) {
            body_html += this.get_price_html(item);
        } else {
            body_html += `<span class="out-of-stock mt-2" style="color: #ff6666;" >${ __( "Price Not Available") }</span>`;
        }

        body_html += this.get_stock_availability(item, settings);
        body_html += this.get_primary_button(item, settings);
        body_html += `</div>`; // close div on line 49

        return body_html;
    }

    get_title(item, title) {
        let title_html = `
            <a href="/${ item.route || '#' }">
                <div class="product-title" itemprop="name">
                    ${ title || '' }
                </div>
            </a>
        `;
        return title_html;
    }

    get_wishlist_icon(item) {
        let icon_class = item.wished ? "wished" : "not-wished";
        return `
            <div class="like-action ${ item.wished ? "like-action-wished" : ''}"
                data-item-code="${ item.item_code }">
                <svg class="icon sm">
                    <use class="${ icon_class } wish-icon" href="#icon-heart"></use>
                </svg>
            </div>
        `;
    }

    get_cart_indicator(item) {
        return `
            <div class="cart-indicator ${item.in_cart ? '' : 'hidden'}" data-item-code="${ item.item_code }">
                1
            </div>
        `;
    }

    get_price_html(item) {
        let price_html = `
            <div class="product-price" itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">
                ${ item.formatted_price !== undefined ? String(item.formatted_price) : `<span class="out-of-stock mt-2" style="color: #ff6666;" >${ __( "Price Not Available") }</span>`}
        `;

        if (item.formatted_mrp) {
            price_html += `
                <small class="striked-price">
                    <s>${ String(item.formatted_mrp) ? item.formatted_mrp.replace(/ +/g, "") : "" }</s>
                </small>
                <small class="ml-1 product-info-green">
                    ${ item.discount } OFF
                </small>
            `;
        }
        price_html += `</div>`;
        return price_html;
    }

    get_stock_availability(item, settings) {
        if (settings.show_stock_availability && !item.has_variants) {
            if (item.on_backorder) {
                return `
                    <span class="out-of-stock mb-2 mt-1" style="color: var(--primary-color)">
                        ${ __("Available on backorder") }
                    </span>
                `;
            } else if (!item.in_stock) {
                return `
                    <span class="out-of-stock mb-2 mt-1">
                        ${ __("Out of stock") }
                    </span>
                `;
            }
        }

        return ``;
    }

    get_primary_button(item, settings) {
        // Check conditions to show "Add to Quote" or "Add to Cart" button
        let show_add_to_quote = (!item.in_stock) || (!item.formatted_price || item.on_backorder);

        if (item.has_variants) {
            return `
                <a href="/${ item.route || '#' }">
                    <div class="btn btn-sm btn-explore-variants w-100 mt-4">
                        ${ __('Explore') }
                    </div>
                </a>
            `;
        } else if (settings.enabled && (settings.allow_items_not_in_stock || item.in_stock)) {
            if (show_add_to_quote) {
                return `
                    <div id="${ item.name }" class="btn
                        btn-sm btn-primary btn-add-to-quote-list
                        w-100 mt-2 ${ item.in_cart ? 'hidden' : '' }"
                        data-item-code="${ item.item_code }">
                        <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                        ${ __('Add to Quote') }
                    </div>

                    <a href="/cart">
                        <div id="${ item.name }" class="btn
                            btn-sm btn-primary btn-add-to-quote-list
                            w-100 mt-4 go-to-cart-grid
                            ${ item.in_cart ? '' : 'hidden' }"
                            data-item-code="${ item.item_code }">
                            ${ __('Go to Cart') }
                        </div>
                    </a>
                `;
            } else {
                return `
                    <div id="${ item.name }" class="btn
                        btn-sm btn-primary btn-add-to-cart-list
                        w-100 mt-2 ${ item.in_cart ? 'hidden' : '' }"
                        data-item-code="${ item.item_code }">
                        <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                        ${__('Add to Order')}
                    </div>

                    <a href="/cart">
                        <div id="${ item.name }" class="btn
                            btn-sm btn-primary btn-add-to-cart-list
                            w-100 mt-4 go-to-cart-grid
                            ${ item.in_cart ? '' : 'hidden' }"
                            data-item-code="${ item.item_code }">
                            ${ __('Go to Cart') }
                        </div>
                    </a>
                `;
            }
        } else {
            return ``;
        }
    }
};
